import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/mypage',
    name: 'mypage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/mypage.vue'),
    children: [
      {
        path: '/profile',
        name: 'memberEdit',
        component: () => import('../components/MemberEdit.vue')
      },
      {
        path: '/delete-account',
        name: 'deleteAccount',
        component: () => import('@/components/DeleteAccount.vue')
      },
      {
        path: '/result/:examId',
        name: 'examResult',
        component: () => import('@/components/ExamResult.vue'),
        props: true // route.params가 컴포넌트의 props로 전달
      }
    ]
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../views/Admin.vue'),
    children: [
      {
        path: '/members',
        name: 'members',
        component: () => import('../components/Members.vue')
      },
      {
        path: '/members/:memberId',
        name: 'memberDetails',
        component: () => import('../components/MemberDetails.vue'),
        props: true
      },
      {
        path: '/exams',
        name: 'exams',
        component: () => import('../components/Exams.vue')
      },
      {
        path: '/exams/:examId',
        name: 'examDetail',
        component: () => import('../components/ExamDetail.vue'),
        props: true
      },
      {
        path: '/license-questions/:examId',
        name: 'licenseQuestions',
        component: () => import('../components/LicenseQuestions.vue'),
        props: true
      },
      {
        path: '/rounds/:examId',
        name: 'rounds',
        component: () => import('../components/Rounds.vue'),
        props: true
      },
      {
        path: '/questions/:roundId',
        name: 'questions',
        component: () => import('../components/Questions.vue'),
        props: true
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "about" */ '../views/RegisterView.vue')
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import(/* webpackChunkName: "about" */ '../views/ForgotPasswordView.vue')
  },
  {
    path: '/changepassword/:token',
    name: 'ChangePassword',
    component: () => import('../views/ChangePasswordView.vue')
  },
  {
    path: '/round/choice',
    name: 'RoundChoice',
    component: () => import(/* webpackChunkName: "round-choice" */ '../views/RoundChoiceView.vue')
  },
  {
    path: '/exam/start',
    name: 'examStart',
    component: () => import('../views/ExamView.vue')
  },
  {
    path: '/exam/solved',
    name: 'examSolved',
    component: () => import('../views/ExamSolvedView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
