<template>
  <div class="container-home">
    <!-- 시험 ID를 LicenseModal로 전달 -->
    <LicenseModal :showModal="showModal" :examId="selectedExamId" @update:showModal="showModal = $event" />
    <div class="fixed-card-container justify-content-center">
      <div v-for="exam in exams" :key="exam.exam_id" class="fixed-card">
        <div class="card mb-4 shadow-sm card-margin">
          <div class="card-body d-flex flex-column">
            <h5 class="card-title">{{ exam.name }}</h5>
            <img :src="exam.image_url" class="card-img mb-3" :alt="exam.name">

            <div v-if="isAuthenticated">
              <!-- 만료일이 있을 때는 날짜를 표시하고, 없을 때는 공백을 출력 -->
              <div class="expiration-date">
                {{ exam.expiration_date ? '만료일: ' + formatDate(exam.expiration_date) : "ㅤ" }}
              </div>
              <button @click="handleButtonClick(exam)" class="btn btn-block mt-auto" :class="{
              'btn-primary': isAllowed(exam.allowed),
              'btn-secondary': exam.allowed === 'DISALLOW',
              'btn-expired': exam.allowed === 'EXPIRED'
            }">
                {{ isAllowed(exam.allowed) ? '시험 응시' : getDisabledMessage(exam.allowed) }}
              </button>
            </div>

            <div v-else>
              <div class="expiration-date">
                {{ exam.expiration_date ? '만료일: ' + formatDate(exam.expiration_date) : "ㅤ" }}
              </div>
              <router-link to="/login" class="btn btn-secondary btn-block mt-auto">
                로그인 후 시험 응시 가능
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/utils/axios'
import { mapGetters } from 'vuex'
import LicenseModal from '@/components/LicenseModal.vue'

export default {
  components: {
    LicenseModal
  },
  data () {
    return {
      exams: [],
      showModal: false,
      selectedExamId: '' // 선택한 시험 ID 저장
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'user'])
  },
  methods: {
    async fetchExams () {
      let response
      if (this.isAuthenticated) {
        response = await axios.get(`/api/license?memberId=${this.user.id}`)
      } else {
        response = await axios.get('/api/exam')
      }
      this.exams = response.data.data.body
    },
    isAllowed (allowed) {
      return allowed === 'ALLOW'
    },
    getDisabledMessage (allowed) {
      if (allowed === 'EXPIRED') {
        return '라이선스 만료됨'
      } else if (allowed === 'DISALLOW') {
        return '라이선스 등록'
      }
      return '응시 불가'
    },
    handleButtonClick (exam) {
      if (this.isAllowed(exam.allowed)) {
        this.$router.push({
          path: '/round/choice',
          query: {
            examId: exam.exam_id,
            examName: exam.name
          }
        })
      } else {
        // 모달을 표시하고 선택한 시험 ID를 설정
        this.selectedExamId = exam.exam_id
        this.showModal = true
      }
    },
    formatDate (date) {
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }
      return new Date(date).toLocaleDateString('ko-KR', options)
    }
  },
  mounted () {
    this.fetchExams()
  }
}
</script>

<style scoped>
.container {
  margin-top: 20px;
  justify-content: center;
  align-items: center;
}

.fixed-card {
  margin: 10px; /* 카드 사이 간격 */
}

.card {
  text-align: center;
  height: 280px; /* 카드 높이 */
  width: 280px; /* 카드 너비 */
}

.card-body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card-title {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.card-img {
  width: 130px;
  height: 130px; /* 이미지 크기 */
  object-fit: cover;
  margin: 0 auto; /* 이미지를 가운데 정렬 */
}

.btn {
  margin-top: auto;
  cursor: pointer; /* 마우스를 올리면 손 모양 커서 */
  transition: background-color 0.3s, border-color 0.3s; /* 색상 변경 시 부드러운 전환 효과 */
}

.btn-block {
  width: 100%;
}

.btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
  color: white;
}

.btn-secondary:hover {
  background-color: #5a6268; /* 다소 짙은 회색 */
  border-color: #545b62; /* 더 짙은 회색 */
}

.btn-expired {
  background-color: #e57373;
  border-color: #e57373;
  color: white;
}

.btn-expired:hover {
  background-color: #d25757 !important;
  border-color: #d25757 !important;
  color: white !important;
}

.expiration-date {
  color: #888;
  font-size: 0.9rem;
  margin-bottom: 8px;
  text-align: center;
}
</style>
