import { createStore } from 'vuex'

export default createStore({
  state: {
    user: JSON.parse(localStorage.getItem('user')) || null // 초기화 시 localStorage에서 사용자 정보를 로드
  },
  getters: {
    isAuthenticated: state => !!state.user, // 로그인 여부를 반환하는 getter
    user: state => state.user
  },
  mutations: {
    SET_USER (state, user) {
      state.user = user
      localStorage.setItem('user', JSON.stringify(user)) // 로그인 정보 localStorage에 저장
    },
    LOGOUT (state) {
      state.user = null
      localStorage.removeItem('user') // 로그아웃 시 localStorage에서 제거
      // 다른 탭에도 로그아웃 이벤트 전파
      localStorage.setItem('logout-event', Date.now())
    }
  },
  actions: {
    login ({ commit }, user) {
      commit('SET_USER', user)
    },
    logout ({ commit }) {
      commit('LOGOUT')
    }
  }
})
