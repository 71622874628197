import axios from 'axios'

// const instance = axios.create({
//   baseURL: 'http://localhost:8080',
//   timeout: 1000
// })

const instance = axios.create({
  baseURL: 'https://cbt.ilifo.kr/api',
  timeout: 2000
})

export default instance
