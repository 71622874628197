<template>
  <div class="full-container">
    <nav class="navbar-expand-lg navbar-light bg-light">
      <div class="total-header">
          <!-- Left Side: Ilifo Logo -->
          <div class="navbar-ilifo">
            <a href="http://ilifo.kr" target="_blank">
              <img src="@/assets/ilifo-logo.png" alt="Ilifo Logo" height="40">
            </a>
          </div>
          <!-- Center: 아이리포 CBT 이미지 -->
          <div class="navbar-home">
            <router-link to="/" class="nav-link">
              <img src="@/assets/ilifo-cbt-logo.png" alt="아이리포 CBT 온라인 모의고사" height="50"></router-link>
          </div>
          <div class="navbar-personal">
            <div class="personal-info-lg">
            <template v-if="isAuthenticated">
                  <div class="personal-info-msg">
                    <span class="nav-welcome"><span class="nav-username">{{ user.name }}</span>님 환영합니다.</span>
                  </div>
                  <div class="personal-info-menu">
                    <router-link v-if="isAdmin" to="/members" class="nav-link d-inline-block">관리자메뉴</router-link>
                    <span v-if="isAdmin" class="nav-separator"> | </span>
                    <router-link to="/profile" class="nav-link d-inline-block">마이페이지</router-link>
                    <span class="nav-separator"> | </span>
                    <a href="#" @click.prevent="confirmLogout" class="nav-link d-inline-block">로그아웃</a>
                  </div>
                </template>
                <template v-else>
                  <router-link to="/login" class="nav-link d-inline-block nav-login">로그인</router-link>
                  <span class="nav-separator"> | </span>
                  <router-link to="/register" class="nav-link d-inline-block nav-register">회원가입</router-link>
                </template>
              </div>
            <div class="personal-info-sm">
              <template v-if="isAuthenticated">
      <!-- 프로필 이미지 (버튼 역할) -->
                <div class="profile-dropdown" @click="toggleMenu">
                  <!-- <img :src="user.profileImage" alt="Profile" class="profile-image"> -->
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUx99efHt7JEw_U2qQbRUZotvtzWd_hNv0QA&s" alt="Profile" class="profile-image">
                </div>
                <!-- 하위 메뉴 (로그인 시 보이는 메뉴) -->
                <div v-show="menuOpen" class="dropdown-menus">
                  <div class="dropdown-top">
                    <div class="nav-welcome-sm d-flex justify-content-center">
                      <span class="nav-username" style="color: blue">{{ user.name }}</span>
                      <span>님</span>
                    </div>
                  </div>
                  <div class="dropdown-bottom">
                    <div class="dropdown-menu-container">
                      <router-link v-if="isAdmin" to="/members" class="nav-link d-inline-block" @click="closeMenu">관리자메뉴</router-link>
                      <div class="nav-separator">
                        <span v-if="isAdmin" class="nav-separator"> | </span>
                      </div>
                      <router-link to="/profile" class="nav-link d-inline-block" @click="closeMenu">마이페이지</router-link>
                      <div class="nav-separator">
                      <span class="nav-separator"> | </span>
                      </div>
                      <a href="#" @click.prevent="confirmLogout" class="nav-link d-inline-block">로그아웃</a>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <!-- 하위 메뉴 (비로그인 시 보이는 메뉴) -->
                <div class="menus">
                  <router-link to="/login" class="btn btn-primary btn-sm d-inline-block nav-login-sm">로그인</router-link>
                  <!-- <div class="nav-separator">
                    <span class="nav-separator"> | </span>
                  </div>
                  <router-link to="/register" class="nav-link d-inline-block">회원가입</router-link> -->
                </div>
            </template>
            </div>
          </div>
          <!-- Right Side: Welcome message, Logout and MyPage or Login and Register Links -->
        </div>
      </nav>
      <div class="router-container">
        <router-view/>
      </div>
  </div>
</template>

<script>
import axios from '@/utils/axios'
import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      isAdmin: false, // 관리자 권한 여부 저장
      menuOpen: false
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'user']) // Vuex의 isAuthenticated와 user getter를 맵핑
  },
  watch: {
    isAuthenticated (newVal) {
      if (newVal) {
        this.checkAdminRole() // 로그인 상태가 변경되면 관리자 권한 확인
        this.menuOpen = false
      }
    }
  },
  methods: {
    ...mapActions(['logout']), // Vuex의 logout 액션을 맵핑
    handleStorageEvent (event) {
      if (event.key === 'logout-event') {
        this.handleLogout()
      }
    },
    toggleMenu () {
      this.menuOpen = !this.menuOpen
    },
    closeMenu () {
      this.menuOpen = false // 메뉴 닫기
    },
    confirmLogout () {
      if (confirm('정말 로그아웃 하시겠습니까?')) {
        this.handleLogout()
      }
    },
    handleLogout () {
      this.logout()
      this.menuOpen = false
      this.$router.push('/').then(() => {
        if (this.$route.path === '/') {
          location.reload()
        }
      })
    },
    async checkAdminRole () {
      try {
        const response = await axios.get(`/api/member/validate-role-admin?memberId=${this.user.id}`)
        if (response.data.data && response.data.data.body === true) {
          this.isAdmin = true // 관리자 권한이 확인되면 isAdmin을 true로 설정
        } else {
          this.isAdmin = false // 관리자 권한이 없으면 false로 설정
        }
      } catch (error) {
        console.error('Error checking admin role:', error)
        this.isAdmin = false // 에러가 발생하면 관리자 권한을 false로 설정
      }
    }
  },
  created () {
    if (this.isAuthenticated) {
      this.checkAdminRole() // 로그인된 경우 관리자 권한 확인
    }
    // storage 이벤트 리스너 등록
    window.addEventListener('storage', this.handleStorageEvent)
  },
  beforeUnmount () {
    // 컴포넌트가 언마운트되면 리스너 제거
    window.removeEventListener('storage', this.handleStorageEvent)
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.full-container {
  display: flex;
  flex-direction: column;
}
.container {
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar {
  padding: 10px 0;
}

.navbar-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.nav-link {
  font-weight: bold;
  color: #2c3e50;

  &.router-link-exact-active {
    color: #42b983;
  }
}
.nav-login-sm {
  padding: 0;
}

.nav-welcome {
  font-weight: bold;
  color: #2c3e50;
}

.nav-username {
  color: #0000ff;
}

.nav-separator {
  margin: 0 10px;
  font-weight: bold;
  color: #2c3e50;
}

.personal-info-lg
.personal-info-sm {
  display: none;
}

.profile-dropdown {
  cursor: pointer;
  display: inline-block;
}

.profile-image {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  object-fit: cover;
}

.dropdown-menus {
  display: block;
  position: absolute;
  top: 50px; /* 프로필 이미지 아래에 표시되도록 설정 */
  // left: 75vw;
  left: min(75vw, calc(100% - 130px));
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
  min-width: 120px;
  height: auto;
}

.dropdown-menus a, .dropdown-menus span {
  display: block;
  margin: 10px 0;
  color: black;
  text-decoration: none;
}

.dropdown-menus a:hover {
  color: #007bff;
}

.dropdown-menu-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.dropdown-top {
  background-color: rgba(201, 201, 201, 0.233);
}

.nav-welcome-sm {
  font-weight: bold;
}
</style>
