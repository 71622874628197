<template>
  <div v-if="showModal" class="modal-overlay">
    <div class="modal">
      <div class="modal-header">
        <h5>라이선스 등록</h5>
        <!-- <button @click="closeModal" class="close">&times;</button> -->
        <img src="@/assets/btn_close_x.png" class="close-btn" @click="closeModal">
      </div>
      <div class="modal-body">
        <!-- 드롭다운으로 질문 선택 -->
        <div class="form-group">
          <select v-model="selectedQuestionId" @change="handleQuestionSelect" id="questionSelect" class="form-control">
            <option disabled value="">{{ hintMessage }}</option> <!-- 기본 힌트 메시지 추가 -->
            <option v-for="question in licenseQuestions" :key="question.id" :value="question.id">
              {{ question.book_name }} ({{ question.isbn }})
            </option>
          </select>
        </div>

        <!-- 선택한 질문을 표시 -->
        <div v-if="selectedQuestion">
          <p>{{ selectedQuestion.question }}</p>
          <form @submit.prevent="submitForm">
            <div class="form-group">
              <label for="licenseKey">정답 입력</label>
              <input v-model="licenseKey" type="text" id="licenseKey" class="form-control" required>
            </div>
            <button type="submit" class="btn btn-primary">등록</button>
            <button type="button" @click="closeModal" class="btn btn-secondary">취소</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/utils/axios'
import { mapGetters } from 'vuex' // Vuex에서 getter 가져오기

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    examId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      licenseKey: '',
      licenseQuestions: [], // 라이선스 질문 목록
      selectedQuestionId: null, // 선택한 질문의 ID
      selectedQuestion: null, // 선택한 질문 객체
      hintMessage: '구매한 도서를 선택해주세요' // 기본 힌트 메시지
    }
  },
  computed: {
    ...mapGetters(['user']) // Vuex에서 user를 가져옴
  },
  methods: {
    closeModal () {
      this.licenseKey = '' // 입력값 초기화
      this.selectedQuestionId = null // 선택한 질문 초기화
      this.selectedQuestion = null // 질문 초기화
      this.$emit('update:showModal', false)
    },
    async fetchLicenseQuestions () {
      try {
        const response = await axios.get(`/api/license/license-question?examId=${this.examId}`)
        this.licenseQuestions = response.data.data.body

        // 모달이 열릴 때 기본 옵션을 선택 상태로 설정
        this.selectedQuestionId = '' // 기본 힌트 옵션으로 초기화
      } catch (error) {
        console.error('질문 불러오기 실패', error)
      }
    },
    handleQuestionSelect () {
      // 드롭다운에서 선택한 질문 ID에 해당하는 질문 객체 찾기
      this.selectedQuestion = this.licenseQuestions.find(question => question.id === this.selectedQuestionId)
    },
    async submitForm () {
      try {
        const validationResponse = await axios.post('/api/license/license-question/validation', {
          id: this.selectedQuestionId,
          member_id: this.user.id, // Vuex에서 가져온 member_id
          answer: this.licenseKey
        })

        if (validationResponse.data.message === 'Success') {
          alert('라이선스 등록 완료')
          this.closeModal()
          location.reload()
        } else {
          alert('정답이 올바르지 않습니다. 다시 시도해 주세요.')
        }
      } catch (error) {
        console.error('등록 실패', error)
      }
    }
  },
  watch: {
    examId (newExamId) {
      // examId가 변경될 때마다 질문 데이터를 새로 불러옴
      if (newExamId) {
        this.fetchLicenseQuestions()
      }
    },
    showModal (newShowModal) {
      // 모달이 열릴 때마다 selectedQuestionId를 기본으로 설정
      if (newShowModal) {
        this.selectedQuestionId = '' // 기본 힌트 옵션으로 초기화
        this.selectedQuestion = null // 질문 초기화
      }
    }
  }
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: white; /* 배경색 추가 */
  border: 1px solid #ccc; /* 경계선 추가 */
  position: relative; /* 모달이 화면 가운데에 나타나도록 설정 */
  padding: 20px;
  border-radius: 5px;
  width: 400px;
  height: auto;
  z-index: 1001;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3); /* 그림자 추가 */
  display: block;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-body {
  margin-top: 10px;
}

.form-group {
  margin-bottom: 15px;
}

.form-control {
  width: 100%;
}

.btn {
  margin-right: 10px;
}

.btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
  color: white;
}
</style>
